@import "../../../styles/dashboard/variables.scss";

#UnsubscribeComponent {
  width: 100%;
  .unsubscribeContainer {
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: 48px;
    justify-content: center;
    .headImage {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .imgBox {
        .img {
          width: 273px;
          height: 273px;
        }
      }
    }
    .bodyContent {
      width: 100%;
      display: flex;
      flex-direction: column;
      .unsubscribeContent {
        width: 100%;
        display: flex;
        flex-direction: column;
        h2 {
          color: #2b353a;
          text-align: center;
          font-family: "SourceSansPro";
          font-size: 33px;
          font-style: normal;
          font-weight: 600;
          line-height: 36px;
        }
        .warnText {
          width: 100%;
          display: flex;
          align-items: center;
          padding: 12px;
          gap: 8px;
          border-radius: 8px;
          background: #fff4ec;
          overflow: hidden;
          margin-top: 32px;
          .icon svg {
            color: #b95000;
            font-size: 24px;
          }
          p {
            color: #b95000;
            font-family: "SourceSansPro";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }
        }
        .textItalicConfirm {
          margin-top: 32px;
          color: #848a8d;
          text-align: center;
          font-family: "SourceSansPro";
          font-size: 16px;
          font-style: italic;
          font-weight: 600;
          line-height: 24px;
        }
        .unShipmentBox {
          width: 100%;
          display: flex;
          padding: 8px 12px;
          flex-wrap: wrap;
          gap: 8px 20px;
          border-radius: 4px;
          border: 1px solid #eaebeb;
          margin-top: 12px;
          justify-content: space-between;
          h2 {
            width: 100%;
            text-align: center;
            color: #2b353a;
            font-family: "SourceSansPro";
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
          }
          .ship-1-box {
            width: fit-content;
            display: flex;
            align-items: center;
            gap: 4px;
            p {
              font-family: SourceSansPro;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: #4f575b;
            }
          }
        }
      }
    }
  }
  .reSubscribeContent {
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    margin-top: 48px;
    justify-content: center;
    h2 {
      color: #2b353a;
      text-align: center;
      font-family: "SourceSansPro";
      font-size: 33px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px;
    }
    .text {
      color: #4f575b;
      text-align: center;
      font-family: "SourceSansPro";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-top: 16px;
    }
  }
}
