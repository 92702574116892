@import "../../../../../styles/dashboard/variables.scss";

#LoadBoardsRouteField {
  vertical-align: middle;
  a {
    display: flex;
    padding: 12px 10px;
  }
  &.link {
    &:hover {
      background-color: $secondary25;
    }
  }
  .LoadBoardsRouteFieldcontent {
    min-width: 80px;

    display: flex;
    align-items: center;
    gap: 4px;
    .route {
      color: $secondary300;
      font-size: 13px;
      font-weight: 600;
      line-height: 20px;
    }
    .distance {
      color: $secondary100;
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
    }
  }
}
