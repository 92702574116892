@import "../../../styles/dashboard/variables.scss";

@font-face {
  font-family: SourceSansPro;
  src: url("./../../../../public/assets/fonts/SourceSansPro/SourceSans3.woff");
  src: url("./../../../../public/assets/fonts/SourceSansPro/SourceSans3.woff2");
  src: url("./../../../../public/assets/fonts/SourceSansPro/SourceSans3.ttf");
}

#SubscribersLayout {
  font-family: SourceSansPro;
  padding-top: $pageWrapper;
  margin: 0 auto;
  margin-top: 80px;
  overflow: hidden;
  min-height: 100vh;
  width: 100%;
}
