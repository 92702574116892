@import "../../../styles/dashboard/variables.scss";

#FiltersCheckboxMultiselect {
  width: 180px;
  max-height: 30px;

  .option {
    display: flex;
    align-items: center;
    padding: 8px;
    .optionCheckbox {
      width: 14px;
      height: 14px;
      appearance: none;
      border: 2px solid $primary500;
      border-radius: 4px;
      cursor: pointer;
    }
    .check {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $secondary0;
      font-size: 10px;
      font-weight: 900;
      z-index: 2;
      cursor: pointer;
    }
  }
  .control {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    gap: 8px;

    border-radius: 24px;
    border: 1px solid $secondary50;
    background: $secondary25;

    user-select: none;
    &.selected {
      border: 1px solid $secondary500;
      background: $secondary500;
      color: $secondary0;
      svg {
        color: $secondary0;
      }
    }
  }
  .css-1xc3v61-indicatorContainer,
  .css-1rx0fx6-indicatorContainer,
  .css-leqliy-indicatorContainer,
  .css-15lsz6c-indicatorContainer {
    padding: 0;
  }
  .css-12kibff-ValueContainer {
    max-height: 13px;
  }

  #react-select-2-input {
    max-height: 13px;
  }
  .css-qbdosj-Input {
    margin: 0;
  }
}
